import { Directive, Input, TemplateRef } from '@angular/core';

import { ClassMetadata, MetadataEntity, PropertyMetadata } from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

@Directive({
	selector: '[bpCustomControl]',
})
export class PropertyMetadataControlCustomDirective<TEntity extends MetadataEntity> {

	static ngTemplateContextGuard<TEntity extends MetadataEntity>(
		dir: PropertyMetadataControlCustomDirective<TEntity>,
		context: any,
	): context is { $implicit: PropertyMetadata } {
		return true;
	}

	@Input('bpCustomControlInferFrom') inferFrom!: ClassMetadata<TEntity>;

	@Input('bpCustomControlUseTypeFrom') useTypeFrom?: TEntity;

	@Input('bpCustomControl') propertyName!: NonFunctionPropertyNames<TEntity>;

	constructor(public tpl: TemplateRef<any>) { }

}
