import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

import { Inject, Injectable } from '@angular/core';

import { ZoneService } from '@bp/shared/rxjs';

import { EnvironmentService } from './environment.service';
import { FirebaseAppConfig, FirebaseService, FIREBASE_APP_CONFIG } from './firebase.service';
import { TelemetryService } from './telemetry';

/*
 * Utility class to test firebase functions, store and storage locally.
 */
@Injectable({ providedIn: 'root' })
export class MockFirebaseService extends FirebaseService {

	constructor(
		protected override _telemetry: TelemetryService,
		@Inject(FIREBASE_APP_CONFIG) protected override _firebaseConfig: FirebaseAppConfig,
		zoneService: ZoneService,
		environment: EnvironmentService,
	) {
		super(_telemetry, _firebaseConfig, zoneService, environment);

		this.init$
			.subscribe(() => {
				connectFunctionsEmulator(getFunctions(), 'localhost', 5001);

				connectFirestoreEmulator(getFirestore(), 'localhost', 8080);

				connectStorageEmulator(getStorage(), 'localhost', 9199);
			});
	}

}
