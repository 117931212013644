import { get } from 'lodash-es';

import { Directive, Input } from '@angular/core';

import type { MetadataEntity } from '@bp/shared/models/metadata';
import { ClassMetadata } from '@bp/shared/models/metadata';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ViewEntityBaseComponent<T extends MetadataEntity> {

	get = get;

	@Input() entity!: T | null;

	@Input() metadata!: ClassMetadata<T>;

	@Input() heading?: string | null;

}
