import { isObject } from 'lodash-es';

import { Directive, Input } from '@angular/core';
import type { UrlTree } from '@angular/router';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';

import type { Dictionary } from '@bp/shared/typings';
import { attrBoolValue } from '@bp/shared/utilities';

type PresetRelativeToTargets = 'parent' | 'root' | 'rootFirstChild';

@Directive()
export class OutletLinkRelativeToTargetBaseDirective {

	@Input()
	set outletLinkRelativeToParent(outlets: Dictionary<any[]> | null) {
		this._setCommands(outlets);

		this._relativeTo = this._route.parent;
	}

	@Input()
	set outletLinkRelativeToRootFirstChild(outlets: Dictionary<any[]> | null) {
		this._setCommands(outlets);

		this._relativeTo = this._route.root.firstChild;
	}

	@Input()
	set outletLinkRelativeToRoot(outlets: Dictionary<any[]> | null) {
		this._setCommands(outlets);

		this._relativeTo = this._route.root;
	}

	@Input()
	set outletLinkRelativeTo({ relativeTo, outlets }: {
		relativeTo: PresetRelativeToTargets;
		outlets: Dictionary<any[]> | null;
	}) {
		this._setCommands(outlets);

		this._setRelativeTo(relativeTo);
	}

	protected get _urlTree(): UrlTree {
		return this._router.createUrlTree(this._commands, {
			relativeTo: this._relativeTo,
			queryParams: this.queryParams,
			fragment: this.fragment,
			queryParamsHandling: this.queryParamsHandling,
			preserveFragment: attrBoolValue(this.preserveFragment),
		});
	}

	@Input() queryParams!: Record<string, any>;

	@Input() fragment!: string;

	@Input() queryParamsHandling!: QueryParamsHandling;

	@Input() preserveFragment!: boolean | '';

	@Input() skipLocationChange!: boolean | '';

	@Input() replaceUrlOnLocationHistory!: boolean | '';

	protected _commands: any[] = [];

	protected _relativeTo!: ActivatedRoute | null;

	constructor(
		protected _router: Router,
		protected _route: ActivatedRoute,
	) { }

	private _setRelativeTo(relativeToTarget: PresetRelativeToTargets): void {
		switch (relativeToTarget) {
			case 'parent':
				this._relativeTo = this._route.parent!;
				break;

			case 'rootFirstChild':
				this._relativeTo = this._route.root.firstChild!;
				break;

			case 'root':
				this._relativeTo = this._route.root!;
				break;

			default:
				this._relativeTo = this._route;
				break;
		}
	}

	private _setCommands(outlets: Dictionary<any[]> | null): void {
		if (outlets === null) {
			this._commands = [];

			return;
		}

		if (!isObject(outlets))
			throw new Error('OutletLinkRelativeToTarget accepts only a dictionary where the keys are the outlet names and the values are the route commands');

		this._commands = [{ outlets }];
	}
}
